import { v4 as uuidv4 } from 'uuid';

export const HotProductArr = [
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2023/01/Tra-sua-pho-mai-tuoi.png',
        name: 'Trà Sữa Phô Mai Tươi',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2023/01/Tra-Dao-Dau-Tay-Kem-Pho-Mai.png',
        name: 'Trà Đào Dâu Tây Kem Phô Mai',
        originPrice: '46000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/10/sua-chua-man-hat-sen-.png',
        name: 'Sữa Chua Mận Hạt Sen',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/10/tra-man-hat-sen.png',
        name: 'Trà Mận Hạt Sen',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/O-Long-xoai-kem-coffee.jpg',
        name: 'Ô Long Xoài Kem Cà Phê',
        originPrice: '49000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/Tra-dao-buoi-hong-tran-chau-baby.jpg',
        name: 'Trà Đào Bưởi Hồng Trân  Châu Baby',
        originPrice: '30000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/6ly_hong.png',
        name: 'Instant Milk Tea - Strawberry - Set 6 Ly',
        salePrice: '165000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/6ly_vang.png',
        name: 'Instant Milk Tea - Original - Set 6 ly',
        salePrice: '160000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/Stawberry-Milk-Tea.jpg',
        name: 'Instant Milk Tea - Strawberry',
        salePrice: '26000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/Original-Milk-Tea.jpg',
        name: 'Instant Milk Tea - Original',
        salePrice: '25000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/04/tra%CC%80-xoa%CC%80i-bu%CC%9Bo%CC%9B%CC%89i-ho%CC%82%CC%80ng.png',
        name: 'Trà Xoài Bưởi Hồng',
        originPrice: '30000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/04/tra%CC%80-xoa%CC%80i-bu%CC%9Bo%CC%9B%CC%89i-ho%CC%82%CC%80ng-kem-pho%CC%82-mai.png',
        name: 'Trà Xoài Bưởi Hồng Kem Phô Mai',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/12/Hi%CC%80nh-a%CC%89nh-sp-website_1000x1000_choco-ngu%CC%83-co%CC%82%CC%81c-kem-cafe.png',
        name: 'Choco Ngũ Cốc Kem Cafe',
        originPrice: '30000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/12/Hi%CC%80nh-a%CC%89nh-sp-website_1000x1000_ho%CC%82%CC%80ng-tra%CC%80-ngu%CC%83-co%CC%82%CC%81c-kem-cafe.png',
        name: 'Hồng Trà Ngũ Cốc Kem Cafe',
        originPrice: '30000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/11/Royal-Pearl-Milk-Coffee.png',
        name: 'Royal Pearl Milk Coffee',
        originPrice: '30000',
        salePrice: '22000',
    },
    /* NEW ARRAY */
];
