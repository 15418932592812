import { v4 as uuidv4 } from 'uuid';

export const MilkTeaArr = [
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2023/01/Tra-sua-pho-mai-tuoi.png',
        name: 'Trà Sữa Phô Mai Tươi',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-6.jpg',
        name: 'Sữa Tươi Trân Châu Baby Kem Café',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-6.jpg',
        name: 'Oolong Trân châu Baby Kem Café',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Xanh-2-copy.jpg',
        name: 'Trà Xanh',
        originPrice: '40000',
        salePrice: '25000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Xanh-Sua-Vi-Nhai-1-copy-1.jpg',
        name: 'Trà Xanh Sữa Vị Nhài',
        originPrice: '40000',
        salePrice: '20000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Sua-Matcha-1-copy.jpg',
        name: 'Trà Sữa Matcha',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-O-Long-Sua-2-copy.jpg',
        name: 'Trà Sữa Ô Long',
        originPrice: '40000',
        salePrice: '22000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/O-Long-Thai-Cuc-2-copy.jpg',
        name: 'Ô Long Thái Cực',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Sua-Dau-Tay-2-copy.jpg',
        name: 'Trà sữa dâu tây',
        originPrice: '40000',
        salePrice: '22000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Hong-Tra-2-copy.jpg',
        name: 'Hồng Trà',
        originPrice: '40000',
        salePrice: '25000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-11.jpg',
        name: 'Matcha Đậu Đỏ',
        originPrice: '40000',
        salePrice: '22000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Sua-Socola-1-copy.jpg',
        name: 'Trà Sữa Socola',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Sua-Bac-Ha-2-copy.jpg',
        name: 'Trà Sữa Bạc Hà',
        originPrice: '40000',
        salePrice: '22000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Sua-1-copy.jpg',
        name: 'Trà sữa',
        originPrice: '40000',
        salePrice: '20000',
    },
];
