import { v4 as uuidv4 } from 'uuid';

export const FreshFruitTeaArr = [
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2023/01/Tra-Dao-Dau-Tay-Kem-Pho-Mai.png',
        name: 'Trà Đào Dâu Tây Kem Phô Mai',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/10/tra-man-hat-sen.png',
        name: 'Trà Mận Hạt Sen',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/O-Long-xoai-kem-coffee.jpg',
        name: 'Ô Long Xoài Kem Cà Phê',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/06/Tra-dao-buoi-hong-tran-chau-baby.jpg',
        name: 'Trà Đào Bưởi Hồng Trân Châu Baby',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2022/04/tra%CC%80-xoa%CC%80i-bu%CC%9Bo%CC%9B%CC%89i-ho%CC%82%CC%80ng.png',
        name: 'Trà Xoài Bưởi Hồng',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-2.jpg',
        name: 'Probi Bưởi Trân Châu Sương Mai',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-3.jpg',
        name: 'Probi Xoài Trân Châu Sương Mai',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/tra_xanh_xoai_35d69664c31248faaf3eeade044035ba_grande.jpg',
        name: 'Trà Xanh Xoài',
        originPrice: '40000',
        salePrice: '25000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/Tra-Dau-Tam-Pha-Le-Tuyet-2-copy.jpg',
        name: 'Trà dâu tằm pha lê tuyết',
        originPrice: '40000',
        salePrice: '28000',
    },
    {
        id: uuidv4(),
        amount: 1,
        img: 'https://tocotocotea.com/wp-content/uploads/2021/01/ezgif.com-gif-maker-7.jpg',
        name: 'Hồng Trà Bưởi Mật Ong',
        originPrice: '40000',
        salePrice: '28000',
    },
];
